import React, { useEffect, useState } from 'react'
import { BoxCard, TableBox } from './component/Charts'
import { MdOutlineSsidChart, MdPeopleOutline } from 'react-icons/md'
import { TbSum } from 'react-icons/tb'
import { GrCycle } from 'react-icons/gr'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'

import ApexCharts from 'apexcharts'

const Statistics = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()

  const [data, setData] = useState([])
  const [metrics, setMetrics] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  // useEffect(() => {
  //   if (JSON.parse(themeMode) == "dark") {
  //     pieChartRender("#d1d5db")
  //     columnChartRender("#d1d5db")
  //     lineChartRender("#d1d5db")
  //   }
  //   else if (JSON.parse(themeMode) == "light") {
  //     pieChartRender("#6b7280")
  //     columnChartRender("#6b7280")
  //     lineChartRender("#6b7280")
  //   }
  // }, [themeMode])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosPrivate.get('/record/home-record-list', {
          signal: controller.signal,
        })

        if (isMounted) {
          // console.log(response.data);
          setData(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }
      setIsLoading(false)
    }

    fetchData()

    pieChartRender("#6b7280")
    columnChartRender("#6b7280")
    lineChartRender("#6b7280")

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/record/home-data', {
          signal: controller.signal,
        })

        if (isMounted) {
          // console.log(response.data);
          setMetrics(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  function pieChartRender(labelColor) {
    var chart = document.querySelector('#chartpie')
    chart.innerHTML = ''
    var options = {
      series: [100, 343, 210],
      chart: {
        height: 350,
        type: 'pie',
      },
      labels: ['Scope 1', 'Scope 2', 'Scope 3'],
      legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'left',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }

    var chart = new ApexCharts(chart, options)
    chart.render()
  }

  function columnChartRender(labelColor) {
    var chart = document.querySelector('#chartcolumn')
    chart.innerHTML = ''
    var options = {
      series: [
        {
          name: 'Scope 1',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: 'Scope 2',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
        },
        {
          name: 'Scope 3',
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 400,
        labels: {
          // colors: '#9ca3af',
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        title: {
          text: 'Months',
          style: {
            color: labelColor,
            fontWeight: '500',
            fontSize: '12px',
          },
        },
        categories: [
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
        ],
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      yaxis: {
        title: {
          text: 'kg CO2',
          style: {
            color: labelColor,
            fontWeight: '500',
            fontSize: '14px',
          },
        },
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return +val + ' kg CO2'
          },
        },
      },
    }

    var chart = new ApexCharts(chart, options)
    chart.render()
  }

  function lineChartRender(labelColor) {
    var chart = document.querySelector('#chartline')
    chart.innerHTML = ''
    var options = {
      series: [
        {
          name: 'kg CO2',
          data: [28, 96, 33, 51, 76, 32, 100],
        },
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          autoSelected: 'zoom',
          style: {
            color: 'white',
          },
        },
      },
      colors: ['#77B6EA'],
      dataLabels: {
        enabled: true,
      },
      title: {
        text: 'Moving Average Graph',
        align: 'left',
        style: {
          fontWeight: 'bold',
          color: labelColor,
        },
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        title: {
          text: 'Months',
          style: {
            color: labelColor,
            fontWeight: '500',
            fontSize: '12px',
          },
        },
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      yaxis: {
        title: {
          text: 'Temperature',
          style: {
            color: labelColor,
            fontWeight: '500',
            fontSize: '14px',
          },
        },
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    }

    var chart = new ApexCharts(chart, options)
    chart.render()
  }
  // const data = [
  //   { username: 'User_1', kgCO2e: 39.65, scope: 'Scope 1' },
  //   { username: 'User_2', kgCO2e: 75.5, scope: 'Scope 2' },
  //   { username: 'User_3', kgCO2e: 55.08, scope: 'Scope 1' },
  //   { username: 'User_4', kgCO2e: 75.08, scope: 'Scope 1' },
  //   { username: 'User_5', kgCO2e: 38.68, scope: 'Scope 2' },
  //   { username: 'User_6', kgCO2e: 76.14, scope: 'Scope 3' },
  //   { username: 'User_7', kgCO2e: 23.68, scope: 'Scope 1' },
  //   { username: 'User_8', kgCO2e: 45.19, scope: 'Scope 2' },
  //   { username: 'User_9', kgCO2e: 80.31, scope: 'Scope 2' },
  //   { username: 'User_10', kgCO2e: 75.14, scope: 'Scope 3' },
  // ];

  return (
    <div className="flex flex-col bg-slate-400 dark:bg-stone-800 w-full h-full overflow-y-auto max-h-full max-w-full">
      <div className="grid pl-4 pr-4 font-['Inter'] rounded-3xl overflow-y-auto mt-8">
        {/* mb-4 pb-10 px-8 mx-4 */}
        <div className="grid grid-cols-12 gap-6">
          <div className="grid grid-cols-12 col-span-12 gap-6 xxl:col-span-9">
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <a
                  className="border border-gray-300 transform  hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-12 sm:col-span-6 xl:col-span-3 intro-y bg-white dark:bg-darkbg4"
                  href="#"
                >
                  <div className="p-5">
                    <div className="flex justify-between">
                      <TbSum className="h-7 w-7 text-blue-400" />
                      <div className="bg-green-500 rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                        <span className="flex items-center">30%</span>
                      </div>
                    </div>
                    <div className="ml-2 w-full flex-1">
                      <div>
                        <div className="mt-3 text-3xl font-bold leading-8 dark:text-white">
                          4.510
                        </div>

                        <div className="mt-1 text-base text-gray-600 dark:text-gray-400">
                          Total Carbon Emission
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  className="transform border border-gray-300 hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-12 sm:col-span-6 xl:col-span-3 intro-y bg-white dark:bg-darkbg4"
                  href="#"
                >
                  <div className="p-5">
                    <div className="flex justify-between">
                      <MdOutlineSsidChart className="h-7 w-7 text-yellow-400" />
                      <div className="bg-red-500 rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                        <span className="flex items-center">30%</span>
                      </div>
                    </div>
                    <div className="ml-2 w-full flex-1">
                      <div>
                        <div className="mt-3 text-3xl font-bold leading-8 dark:text-white">
                          4.510
                        </div>

                        <div className="mt-1 text-base text-gray-600 dark:text-gray-400">
                          Yearly Change in Carbon Percentage
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  className="transform border border-gray-300 hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-12 sm:col-span-6 xl:col-span-3 intro-y bg-white dark:bg-darkbg4"
                  href="#"
                >
                  <div className="p-5">
                    <div className="flex justify-between">
                      <GrCycle className="h-7 w-7 text-pink-600" />
                      <div className="bg-yellow-500 rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                        <span className="flex items-center">30%</span>
                      </div>
                    </div>
                    <div className="ml-2 w-full flex-1">
                      <div>
                        <div className="mt-3 text-3xl font-bold leading-8 dark:text-white">
                          4.510
                        </div>

                        <div className="mt-1 text-base text-gray-600 dark:text-gray-400">
                          Carbon Offset
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  className="transform border border-gray-300 hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-12 sm:col-span-6 xl:col-span-3 intro-y bg-white dark:bg-darkbg4"
                  href="#"
                >
                  <div className="p-5">
                    <div className="flex justify-between">
                      <MdPeopleOutline className="h-7 w-7 text-green-400" />
                      <div className="bg-blue-500 rounded-full h-6 px-2 flex justify-items-center text-white font-semibold text-sm">
                        <span className="flex items-center">30%</span>
                      </div>
                    </div>
                    <div className="ml-2 w-full flex-1">
                      <div>
                        <div className="mt-3 text-3xl font-bold leading-8 dark:text-white">
                          4.510
                        </div>

                        <div className="mt-1 text-base text-gray-600 dark:text-gray-400">
                          Carbon Footprint per Employee
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-span-12 mt-5 ">
              <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 gap-6  ">
                <div className="bg-white flex shadow-lg  dark:bg-darkbg4 border border-gray-300 rounded-md overflow-y-auto h-96">
                  <table className="min-w-full h-full table-fixed  divide-y divide-gray-200 table-fixed">
                    <thead className="sticky top-0 z-0">
                      <tr>
                        <th className="dark:bg-gray-800 dark:text-gray-400 px-6 py-3 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          <div className="flex cursor-pointer">
                            <span className="mr-2">Username</span>
                          </div>
                        </th>
                        <th className="dark:bg-gray-800 dark:text-gray-400 px-6 py-3 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          <div className="flex cursor-pointer">
                            <span className="mr-2">kg CO2e</span>
                          </div>
                        </th>
                        <th className="dark:bg-gray-800 dark:text-gray-400 px-6 py-3 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          <div className="flex cursor-pointer">
                            <span className="mr-2">Scope</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {/* <tr className="dark:bg-darkbg4 hover:bg-gray-50 dark:hover:bg-gray-600 dark:text-white">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 ">
                          <p>Apple MacBook Pro 13</p>
                          <p className="text-xs text-gray-400">PC & Laptop</p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                          <p>77</p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                          <div className="flex text-green-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5 mr-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <p>Active</p>
                          </div>
                        </td>
                      </tr>
                      <tr className="dark:bg-darkbg4 hover:bg-gray-50 dark:hover:bg-gray-600 dark:text-white">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 ">
                          <p>Sc</p>
                          <p className="text-xs text-gray-400">PC & Laptop</p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                          <p>77</p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                          <div className="flex text-green-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5 mr-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <p>Active</p>
                          </div>
                        </td>
                      </tr>
                      <tr className="dark:bg-darkbg4 hover:bg-gray-50 dark:hover:bg-gray-600 dark:text-white">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 ">
                          <p>Apple MacBook Pro 13</p>
                          <p className="text-xs text-gray-400">PC & Laptop</p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                          <p>77</p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                          <div className="flex text-green-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5 mr-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <p>Active</p>
                          </div>
                        </td>
                      </tr>
                      <tr className="dark:bg-darkbg4 hover:bg-gray-50 dark:hover:bg-gray-600 dark:text-white">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 ">
                          <p>Apple MacBook Pro 13</p>
                          <p className="text-xs text-gray-400">PC & Laptop</p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                          <p>77</p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                          <div className="flex text-green-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5 mr-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <p>Active</p>
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                <div
                  className="bg-white shadow-lg items-center justify-center flex border dark:bg-darkbg4 border-gray-300 rounded-md h-96"
                  id="chartpie"
                ></div>
              </div>
            </div>
            <div className="col-span-12 mt-5 border border-gray-300 rounded-md shadow-lg dark:bg-darkbg4">
              <div
                className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 p-4"
                id="chartcolumn"
              ></div>
            </div>

            <div className="col-span-12 mt-5 border border-gray-300 rounded-md shadow-lg dark:bg-darkbg4 mb-10">
              <div
                className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 p-4"
                id="chartline"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Statistics
